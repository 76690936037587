
import {Component, Vue} from 'vue-property-decorator';
import {EmailAddressControllerApi} from "../client";
import http from "../plugins/http";

@Component({})
export default class EmailConfirmation extends Vue {
    private emailAddressControllerApi = new EmailAddressControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);

    private confirmationInProgress = true;
    private confirmedEmailAddress: string | null = null;

    mounted() {
        this.emailAddressControllerApi.confirmRegistration(this.$route.query.id as string)
        .then(response => {
          this.confirmedEmailAddress = response.data;
        })
        .catch(() => {
          this.confirmedEmailAddress = null;
        })
        .then(() => {
          this.confirmationInProgress = false;
        });
  }
}
